import React from 'react';
import { Spring } from 'react-spring/renderprops';
import { theme } from 'lib/theme';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';
import {
  Body,
  Headline1,
  Nbsp,
  TitleAlt,
} from 'components/Typography';
import { LinkExternal } from 'components/LinkExternal';


export const Hero = () => (
  <Gridset
    flexGrow={1}
    gridTemplateRows="100%"
    height="100%"
    maxWidth="100vw"
    paddingBottom="gutterInside"
    paddingX={theme.space.viewportPaddingX}
  >
    <Box
      alignSelf="center"
      as="header"
      color="app.text"
      gridColumn={{
        base: 'span 12',
        md: 'a05 / a55',
        lg: 'a05 / a4',
        xl: 'a05 / a35',
      }}
      justifySelf={'start'}
      paddingX={{ base: 0, sm: 0.5 * theme.sizes.gridset.col6 + '%', md: 0 }}
      paddingY={{ base: 8, md: 2 }}
    >
      <Headline1
        as="h1"
        color="app.textHighlight"
        fontSize={{
          base: '3xl',
          lg: '4xl',
          xl: '6xl',
        }}
        lineHeight="tall"
      >
        Hi, <span style={{ whiteSpace: 'nowrap' }}>I’m Harlan.</span>
      </Headline1>
      <Spring
        config={{ tension: 30 }}
        delay={0}
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
      >
        {springStyle => (
          <TitleAlt
            as="h2"
            lineHeight="tall"

            style={springStyle}
          >
            I design products and services.
          </TitleAlt>
        )}
      </Spring>
      <Spring
        config={{ tension: 20, friction: 10 }}
        delay={150}

        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
      >
        {springStyle => (
          <Box marginTop={12} style={springStyle}>
            <Body as="p" marginY={4}>
              I've led design work on products and services for:
            </Body>
            <div className="typographyEnabled">
              <ul>
                <li>technical infrastructure access management (Head of Product Design, StrongDM)</li>
                <li>predictive behavorial analysis for growth metric optimization (Product Design, Spotify)</li>
                <li>customer<Nbsp />success management (Head of Product Design, Preact)</li>
                <li>in-store<Nbsp />point<Nbsp />of<Nbsp />sale and ecommerce for college course materials (Lead Product Designer, Rafter)</li>
                <li>legal<Nbsp />services marketplace and client intake platform (Lead Designer, Legal.io)</li>
              </ul>
            </div>
            <Body as="p" marginY={4}>On the side, I've been working on a variety of LLM-driven tools, like this <LinkExternal href="https://trips.harlanlewis.com" target="_trips">automated trip planner</LinkExternal>. I also publish a <LinkExternal href="https://docs.google.com/spreadsheets/d/1foc98Jtbi0-GUsNySddvL0b2a7EuVQw8MoaQlWaDT-w" target="_llm_sheet">LLM models &amp; providers dashboard</LinkExternal> for visualizing the rapidly-changing capability/cost/throughput landscape from a procurement perspective.</Body>
            <Body as="p" marginY={4}>My favorite work is helping teams consistently ship impactful, human-centered products that make the complex feel familiar.</Body>
            <Body as="p" marginY={4}>In my free time, I design and create outdoors equipment and am a certified wilderness first responder.</Body>
          </Box>
        )}
      </Spring>
    </Box>
  </Gridset>
);
